import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class SubscriptionsService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listSubscriptions (filters) {
		const response = await this.axios.get("subscriptions/listSubscriptions", { params: { filters }});
		return response.data;
	}

	async getSubscriptionType () {
		const response = await this.axios.get("subscriptions/getSubscriptionType");
		return response.data;
	}

	async upsertSubscription (payload) {
		const response = await this.axios.post("subscriptions/upsertSubscription", payload);
		return response.data;
	}

	async executePay (payload) {
		const response = await this.axios.post("subscriptions/executePay", payload);
		return response.data;
	}


	// async deleteUser (id) {
	// 	const response = await this.axios.delete("users/deleteUser", { params: id });
	// 	return response.data;
	// }
}
